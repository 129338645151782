<template>
  <section class="notifications--wrapper">
    <div class="notif--title">
      {{ $t('home.sidebarMenu.notification') }}
    </div>
    <section-loader :show="loading" />
    <notification-item
      v-for="notification in notificationList"
      :notification="notification"
      :key="`${notification.id}${notification.createdAt}`"
    />
    <pagination
      :push-state="false"
      :meta="metaPagination"
      @changePage="getNotifications"
      :maxVisibleButtons="5"
    />
  </section>
</template>

<script>
const NotificationItem = () => import('@/components/notification/partials/notification-item');
const SectionLoader = () => import('@/components/content-loading/section-loading');
const Pagination = () => import('@/components/utils/fractal-pagination');

import { mapState } from 'vuex';

export default {
  components: {
    NotificationItem,
    Pagination,
    SectionLoader,
  },
  computed: {
    ...mapState({
      notificationList: state => state.v2.notification.notificationList,
      metaPagination: state => state.v2.notification.metaPagination,
      loading: state => state.v2.notification.loading,
    }),
  },
  methods: {
    getNotifications(page) {
      this.$store.dispatch('v2/notification/getNotifications', page);
    },
  },
};
</script>
